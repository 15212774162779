export default {
    namespaced: true,
    state () {
      return {
        loginUser:null,
      }
    },
    mutations: {
      setLoginUser(state, user) {
        state.loginUser = user;
      },
      getLoginUser(state){
        return state.loginUser;
      }
    },
    actions: {
      
    }
  }