import { createRouter, createWebHashHistory } from 'vue-router'
//import store from '../store/index';

const Index = () => import('@/views/index')
const Piano=()=>import('@/views/piano/index')
const Home=()=>import('@/views/home/index')
const ScoreFound=()=>import('@/views/scoreFound/index')
const Login=()=>import('@/views/login/index')
const Personal=()=>import('@/views/personal/index')
const AccInfo=()=>import('@/views/personal/views/accInfo/index')
const MyCollect=()=>import('@/views/personal/views/myCollect/index')
const MyUpload=()=>import('@/views/personal/views/myUpload/index')
const UploadScore=()=>import('@/views/personal/views/uploadScore/index')

const routes = [
  {
    path: '/', component: Index,
    children: [
      { path: '/', component: Home },
      { path:'/scoreFound',component:ScoreFound},
      {
        path: '/personal',component:Personal,
        redirect: '/accInfo',
        children:[
          {path:'/accInfo',component:AccInfo},
          {path:'/myCollect',component:MyCollect},
          {path:'/myUpload',component:MyUpload},
          {path:'/uploadScore',component:UploadScore}
        ]
        // beforeEnter:(to,from,next)=>{
        //   if(store.state.user.loginUser==null||store.state.user.loginUser==''){
        //     next('/login');
        //   }else{
        //     next();
        //   }
        // }
      }
    ]
  },
  {
    path: '/piano/:id',component:Piano,
  },
  {
    path:'/login',component:Login
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(){
    return {left:0,top:0}
  }
})

export default router

