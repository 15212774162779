export default {
    namespaced: true,
    state () {
      return {
        nav:[
            {name:'首页',link:'/',icon:'icon-piano'},
            {name:'个人中心',link:'/accInfo',icon:'icon-personal'},
            {name:'发现乐谱',link:'/scoreFound',icon:'icon-score'},
            {name:'开始弹奏',link:'/piano/-1',icon:'icon-start'}
        ],
        pNav:[
            {name:'账号信息',link:'/accInfo',icon:'icon-accInfo'},
            {name:'我的收藏',link:'/myCollect',icon:'icon-collect'},
            {name:'上传乐谱',link:'/uploadScore',icon:'icon-upload'},
            {name:'我的上传',link:'/myUpload',icon:'icon-myUpload'}
        ]
      }
    },
    mutations: {
      setNav(state, nav) {
        state.nav = nav;
      },
      getNav(state){
        return state.nav;
      }
    },
    actions: {
    }
  }