export default {
    namespaced: true,
    state () {
      return {
        images:[],
        pianoSet:{
          leftPos:3,
          rightPos:4,
          leftE:"front",
          rightE:"rear"
        }
      }
    },
    mutations: {
      setPianoSet(state,pianoSet){
        state.pianoSet=pianoSet;
      },
      setImages (state, imgs) {
        //console.log("dss");
        //console.log(imgs);
        state.images = imgs;
      }
      // getImages(state){
      //   return state.images;
      // }
    },
    actions: {
      getList ({ commit }) {
        let result=["a","b"];
        commit('setImages', result);
      }
    }
  }