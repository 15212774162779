<template>
<div class="pButton" :class="clas">
  <slot/>
</div>
</template>
<script>
import { watch,ref} from 'vue'
export default {
  name: 'PButton',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default:'default'
    },
    active:{
      type:Boolean,
      default:false
    },
    hoverActive:{
      type:Boolean,
      default:true
    }
  },
  setup (props) {
    const clas=ref([]);
    watch(()=>props.active,(newVal)=>{
      if(newVal==true){
        clas.value=[];
        clas.value.push(props.size);
        clas.value.push(props.type);
        if(props.type=='default'){
          clas.value.push('def-active');
        }
      }else{
        clas.value=[];
        clas.value.push(props.size);
        clas.value.push(props.type);
      }
      if(props.hoverActive==true){
        clas.value.push("hoverActive");
      }
    },{immediate:true})
    return {clas}
  }
}
</script>
<style scoped lang="less">
.pButton {
  text-align: center;
  cursor: pointer;
}
.large {
  width: 240px;
  height: 50px;
  font-size: 16px;
}
.middle {
  width: 180px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  font-weight: 800;
}
.small {
  width: 110px;
  height: 45px;
  line-height: 40px; 
  font-size: 18px; 
  font-weight: 800;
}
.mini {
  width: 100px;
  height: 35px;
  line-height: 32px; 
  font-size: 14px;  
  font-weight: 500;
}
.loginSize{
  width: 300px;
  height: 40px;
  line-height: 40px; 
  font-size: 16px;
}

.default {
  border: solid;
  border-radius: 5px;
  background-color: white;
  
  .hoverShadow();
  
}
.def-active{
  background-color: black;
  color: white;
}


.hoverActive{
  &:hover{
    background-color: black;
    color: white;
  }
}
</style>