const importFn = require.context('./', false, /\.vue$/);
export default {
    install (app) {
      // 在app上进行扩展，app提供 component directive 函数
      // 如果要挂载原型 app.config.globalProperties 方式
  
      // 批量注册全局组件
      importFn.keys().forEach(key => {
        // 导入组件
        const component = importFn(key).default
        // 注册组件
        app.component(component.name, component)
      })
    }
}
