import axios from 'axios'
import index from './index'
import qs from 'qs'
axios.defaults.withCredentials=true

export const addCollect=(collect)=>{
    let p=new Promise((resolve,reject)=>{
        axios({
            url:index.baseUrl+'/addCollect',
            method:'post',
            data:collect
        }).then(function(res){
            resolve(res);
        }).catch(function(err){
            reject(err);
        })
    })
    return p;
}

export const deleteCollect=(collect)=>{
    let p=new Promise((resolve,reject)=>{
        axios({
            url:index.baseUrl+'/deleteCollect',
            method:'post',
            data:collect
        }).then(function(res){
            resolve(res);
        }).catch(function(err){
            reject(err);
        })
    })
    return p;
}

export const queryCollectByUserId=(id)=>{
    let p=new Promise((resolve,reject)=>{
        axios({
            url:index.baseUrl+'/queryCollectByUserId',
            method:'post',
            data:qs.stringify({
                id:id
            })
        }).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
    return p;
}

