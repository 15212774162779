import { queryCollectByUserId } from "../../api/collect";
export default{
    namespaced:true,
    state:()=>{
        return {
            collects:[]
        }
    },
    mutations:{
        setCollects(state,data){
            state.collects=data;
        }
    },
    actions:{
        updateCollects(ctx){
            queryCollectByUserId(ctx.rootState.user.loginUser.id).then((res)=>{
                ctx.commit('setCollects',res.data);
            })
        }
    }
}