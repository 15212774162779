import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

import score from './modules/score'
import navs from './modules/navs'
import user from './modules/user'
import collect from './modules/collect'
export default createStore({
  modules:{
    score,
    navs,
    user,
    collect
  },
  plugins: [
    createPersistedstate({
      key: 'piano-loginUser',
      //paths: ['score']
      storage:window.sessionStorage,
      paths:['user.loginUser']
    }),
    createPersistedstate({
      key: 'piano-store',
      paths: ['score']
    })
  ]
})
